import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loginpage from './pages/Loginpage';
import Signuppage from './pages/Signuppage';
import Homepage from './pages/Homepage';
import ProfilePage from './pages/ProfilePage';
import Transactionspage from './pages/Transactionspage';
import Forgetpage from './pages/Forgetpage';
import Notificationpage from './pages/Notificationpage';
import Depositpage from './pages/Depositpage';
import Withdrawpage from './pages/Withdrawpage';
import NotFoundPage from './pages/NotFoundPage';
import ProtectedRoute from './ProtectedRoute';

const App = () => {
  useEffect(() => {
    const preventZoom = (e) => {
      if (e.ctrlKey && (e.key === '+' || e.key === '-' || e.key === '0')) {
        e.preventDefault();
      }
    };

    const preventWheelZoom = (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', preventZoom);
    window.addEventListener('wheel', preventWheelZoom, { passive: false });

    return () => {
      window.removeEventListener('keydown', preventZoom);
      window.removeEventListener('wheel', preventWheelZoom);
    };
  }, []);

  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Loginpage />} />
        <Route path="/signup" element={<Signuppage />} />
        <Route path="/forget" element={<Forgetpage />} />

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/home" element={<Homepage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/transactions" element={<Transactionspage />} />
          <Route path="/notification" element={<Notificationpage />} />
          <Route path="/deposit" element={<Depositpage />} />
          <Route path="/withdraw" element={<Withdrawpage />} />
        </Route>

        {/* Catch-all */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
