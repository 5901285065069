import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import axios from 'axios';
import Nav from '../components/Navigation';
import easypaisa from '../assets/images/deposit/easypaisa.png';
import jazzcash from '../assets/images/deposit/jazzcash.png';
import bank from '../assets/images/deposit/bank.png';
import tick from '../assets/images/topTick.svg';
import upaisa from '../assets/images/deposit/upaisa.png';
import nayapay from '../assets/images/deposit/nayapay.png';
import { GrCopy } from "react-icons/gr";
import { LuImagePlus } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'react-bootstrap/Modal';
import Bottomtab from '../components/BottomTab'
import '../assets/css/Deposit.css'
import { Icons } from '../content/Icons';


import { Helmet } from "react-helmet";

import { sendFCMNotification } from '../components/sendnoti';

import Loader from './Loader';


const Depositpage = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);
  const [loading, setLoading] = useState(false);
    const [tempAmount, setTempAmount] = useState('');
      const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);



  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [navigate, user]);


  const copyToClipboard = () => {
    navigator.clipboard.writeText(accountDetails.account_number)
      .then(() => {

        // alert("Account Number copied to clipboard!");

        setMsg(accountDetails.account_bank_dsc + 'copied successfully');

        handleShow();

      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };



  const [accountDetails, setAccountDetails] = useState({
    account_number: '',
    account_title: '',
    account_bank_name: '',
    account_bank_dsc: '',
    account_title_dsc: '',
  });


  const [selectedFile, setSelectedFile] = useState(null);

  const [dialogBackONoFF, setDialogOnOff] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const options = [
    { value: '1000', label: '1000' },
    { value: '2000', label: '2000' },
    { value: '5000', label: '5000' },
    { value: '10000', label: '10000' },
  ];
  const [amount, setAmount] = useState("");
  const handleChange = (event) => {
    setAmount(event.target.value);
  };
  const [selectedPrice, setSelectedPrice] = useState('');

  const [selectedImageUrl, setImageUrl] = useState('');

  const handleClick = (price) => {
    setSelectedPrice(price);
    setAmount(price);
  };

  const handleSuccessBtnOk = () => {
    setShow(false);
    setLoading(false);

    setIsPaymentSuccess(false);
    navigate('/');



}


  const handleCopyClick = (copyTxt) => {

    setMsg(copyTxt + ' copied successfully');

    handleShow();
  };

  const paymentOptions = [
    { id: 1, image: Icons.easypaisaIcon, alt: 'Easypaisa', text: 'Easypaisa' },
    { id: 2, image: Icons.JazzCash, alt: 'JazzCash', text: 'Jazz Cash' },
    { id: 3, image: Icons.BankTransfer, alt: 'Bank', text: 'Bank' },
    { id: 6, image: Icons.raast, alt: 'Raast', text: 'Raast' },
    { id: 4, image: Icons.binance, alt: 'Binance', text: 'Binance' },
    { id: 5, image: Icons.skrill, alt: 'Skrill', text: 'Skrill' },

  ];
  const [selectedPayment, setSelectedPayment] = useState(null);


  const handleMethodChange = (optionId) => {

    accountFetch(optionId); // Fetch account details based on the selected payment method


  };

  const accountFetch = async (paymentId) => {
    const params = new URLSearchParams();
    params.append('account_type', paymentId);
    params.append('table_name', "accounts");

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post('https://api.betprowallet.pk/api_fetch_accounts.php', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      });

      clearTimeout(timeout);

      // Handle the response and update state
      if (response.data && response.data.length > 0) {
        const account = response.data[0]; // Assuming the first item is what you need

        if (account.account_active == 1) {

          setSelectedPayment(paymentId);

          setAccountDetails({
            account_number: account.account_number,
            account_title: account.account_title,
            account_bank_name: account.account_bank_name,
            account_bank_dsc: account.bank_desc,
            account_title_dsc: account.title_dec,

          });

        } else {

          setMsg('Account Not Availble');

          setAccountDetails({
            account_number: '',
            account_title: '',
            account_bank_dsc: '',
            account_title_dsc: '',


          });

          setSelectedPayment(null);
          handleShow();


        }


      } else {
        setAccountDetails({
          account_number: 'N/A',
          account_title: 'N/A',
          account_bank_dsc: '',
          account_title_dsc: '',
        });
      }
    } catch (error) {
      console.error('Error fetching account details:', error);
      setAccountDetails({
        account_number: '',
        account_title: '',
        account_bank_dsc: '',
        account_title_dsc: '',
      });
      setMsg('Failed to fetch Account. Please check your internet connection.');
      setSelectedPayment(null);
      handleShow();
    }
  };


  const [show, setShow] = useState(false);
  const [Msg, setMsg] = useState('');



  const [accountMsgTitle, setAccountMsgTitle] = useState('');

  const handleClose = function () {
    setShow(false);
    setLoading(false);

    if (dialogBackONoFF) {
      navigate('/');
    }

    setDialogOnOff(false);
  };

  const handleShow = () => setShow(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!selectedPayment) {
      setMsg('Please select a Payment Option to proceed');
      handleShow();
    } else if (!selectedFile) {
      setMsg('Please upload image to proceed');
      handleShow();
    } else if (!(Number(amount) >= 500)) {
      setMsg('Please write a correct amount to proceed');
      handleShow();
    } else if (accountDetails.account_number == '') {
      setMsg('Please select a Payment Option to proceed');
      handleShow();
    }
    else {
      // setMsg('Deposit request created successfully');

      // handleShow();
      // Call the function to check for pending deposits
      await checkPendingDeposits(parsedUser.userID, 'pendingusers', setMsg, handleShow);


      // console.log(paymentOptions[selectedPayment].text);


    }
  };



  // Function to check for pending deposits
  const checkPendingDeposits = async (userName, tableName, setMsg, handleShow) => {
    try {


      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 20 seconds timeout

      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('user_id', userName);
      params.append('table_name', tableName);

      // Send the POST request to check for pending deposits
      const response = await axios.post(
        'https://api.betprowallet.pk/api_fetch_pending_deposits.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);




      // Handle the server's response
      // if (response.data.message === '0 results') {
      //   setMsg('No pending deposits found. Proceeding with the deposit request.');
      //   // You can call the next step of processing the deposit here
      //   await checkPendingDeposits(parsedUser.userID,'pendingusers', setMsg, handleShow);


      // } 



      if (response.data[response.data.length - 1].status == '0') {


        setMsg('You already have a pending deposit.');

        handleShow();


      } else {




        await checkDeposits(parsedUser.userID, 'deposit', setMsg, handleShow);


      }








    } catch (error) {
      if (axios.isCancel(error)) {
        setMsg('Request timed out. Please try again.');
      } else {
        setMsg('Please check your internet connection.');
      }
      handleShow();

    }
  };



  // Function to check for pending deposits
  const checkDeposits = async (userName, tableName, setMsg, handleShow) => {
    try {


      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 20 seconds timeout

      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('user_id', userName);
      params.append('table_name', tableName);

      // Send the POST request to check for pending deposits
      const response = await axios.post(
        'https://api.betprowallet.pk/api_fetch_pending_deposits.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);




      // Handle the server's response
      // if (response.data.message === '0 results') {
      //   setMsg('No pending deposits found. Proceeding with the deposit request.');
      //   // You can call the next step of processing the deposit here
      //   await checkPendingDeposits(parsedUser.userID,'pendingusers', setMsg, handleShow);


      // } 



      if (response.data[response.data.length - 1].status == '0') {


        setMsg('You already have a pending deposit.');

        handleShow();


      } else {



        // You can call the next step of processing the deposit here


        uploadImageTo(selectedFile, setImageUrl, setMsg, handleShow);







      }








    } catch (error) {
      if (axios.isCancel(error)) {
        setMsg('Request timed out. Please try again.');
      } else {
        setMsg('Please check your internet connection.');
      }
      handleShow();

    }
  };


  // Function to upload the deposit image
  const uploadImageTo = async (imageFile, setImageUrl, setMsg, handleShow) => {
    try {
      // Read the image file and convert it to Base64
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1]; // Extract Base64 part of the image

        // Prepare the parameters for the POST request
        const params = new URLSearchParams();
        params.append('image', base64Image);

        // Create a cancel token
        const source = axios.CancelToken.source();

        // Start the request
        try {
          const response = await axios.post(
            'https://api.betprowallet.pk/upload_image_to_storage.php',
            params,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              cancelToken: source.token,
              timeout: 60000, // Timeout of 30 seconds for the request
            }
          );

          // Handle the server's response
          const jsonResponse = response.data;

          if (jsonResponse.status === 'success') {
            const imageUrl = jsonResponse.imageUrl;
            setImageUrl(imageUrl); // Save the image URL




            if (Number(parsedUser.userActive) == 1) {

              submitDeposit(
                'deposit',
                parsedUser.userID,
                parsedUser.name,
                ''+selectedPayment,
                amount,
                accountDetails.account_title,
                accountDetails.account_number,
                accountDetails.account_bank_name,
                parsedUser.bp_username,
                imageUrl, // Image URL after upload
                '0',
                parsedUser.number,
                setMsg,
                handleShow
              );

            } else {

              submitDeposit(
                'pendingusers',
                parsedUser.userID,
                parsedUser.name,
                ''+selectedPayment,
                amount,
                accountDetails.account_title,
                accountDetails.account_number,
                accountDetails.account_bank_name,
                parsedUser.bp_username,
                imageUrl, // Image URL after upload
                '0',
                parsedUser.number,
                setMsg,
                handleShow
              );

            }


          } else {
            setMsg('Image upload failed.');
            handleShow();
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            setMsg('Image upload was canceled due to timeout.');
          } else if (error.code === 'ECONNABORTED') {
            setMsg('Image upload request took too long. Please try again.');
          } else {
            setMsg('Image upload failed. Please check your internet connection.');
          }
          handleShow();
        }
      };

      // Handle file reading error
      reader.onerror = () => {
        setMsg('Error reading image file. Please try again.');
        handleShow();
      };

      // Start reading the image file as Base64
      reader.readAsDataURL(imageFile);
    } catch (error) {
      setMsg('An unexpected error occurred. Please try again.');
      handleShow();
    }
  };


  // Function to submit deposit details to the server
  const submitDeposit = async (
    tableName,
    id,
    username,
    accType,
    amount,
    accTitle,
    accNum,
    bankName,
    betProUsername,
    downloadUrl,
    status,
    whatsapp,
    setMsg,
    handleShow
  ) => {
    try {
      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('user_id', id);
      params.append('acc_type', accType);
      params.append('amount', amount);
      params.append('to', accTitle);
      params.append('acc', accNum);
      params.append('deposit_bank_name', bankName);
      params.append('bet_pro_username', betProUsername);
      params.append('proof', downloadUrl); // Image URL
      params.append('status', status);
      params.append('whatsapp', whatsapp);
      params.append('table_name', tableName);

      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 30 seconds timeout

      // Send the POST request to submit the deposit details
      const response = await axios.post(
        'https://api.betprowallet.pk/pendingUserInsert.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);

      // Handle the server's response
      if (response.data.status === 'success') {



        if (Number(parsedUser.userActive) == 0) {

          await sendFCMNotification('/topics/com.b.adminweb', 'Deposit', 'Deposit of Amount ' + amount + ' For User Activation');
        } else {

          await sendFCMNotification('/topics/com.b.adminweb', 'Deposit', 'Deposit of Amount ' + amount);

        }

        setMsg('Deposit submitted successfully!');
        setIsPaymentSuccess(true);
        setTempAmount(amount);
        setShow(false);
        setLoading(false);


      } else {
        setMsg('Failed to submit deposit. Please try again.');
        handleShow();
        setDialogOnOff(true);
      }

     
    } catch (error) {
      if (axios.isCancel(error)) {
        setMsg('Deposit submission timed out. Please try again.');
      } else {
        setMsg('Failed to submit deposit. Please check your internet connection.');
      }
      handleShow();
      setDialogOnOff(true);
    }
  };






  return (
    <div>


      <Helmet>
        {/* Page title optimized for the deposit page */}
        <title>Betpro Wallet Deposit - Fast and Secure Deposits</title>
        <link rel="icon" href="https://betprowallet.pk/favicon.ico" />

        {/* Canonical tag */}
        <link rel="canonical" href="https://betprowallet.pk/deposit" />


        {/* Meta description focusing on depositing funds to Betpro Wallet */}
        <meta name="description" content="Deposit funds securely into your Betpro Wallet account. ⚽️ Fast and easy deposits with multiple payment options. Start betting now with Betprowallet.pk - Safe, reliable, and instant transactions." />

        {/* Meta keywords for search engine optimization */}
        <meta name="keywords" content="Betpro Wallet deposit, fast deposits, secure deposits, deposit funds Betpro Wallet, Betpro Wallet payment options, deposit betting account, Betprowallet.pk deposit" />

        {/* Meta author */}
        <meta name="author" content="Betpro Wallet" />

        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Betpro Wallet Deposit - Fast and Secure Deposits" />
        <meta property="og:description" content="Deposit funds into your Betpro Wallet account securely and quickly. Choose from multiple payment options for a safe betting experience at Betprowallet.pk." />
        <meta property="og:url" content="https://betprowallet.pk/deposit" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://betprowallet.pk/bpthumb1.jpg" />

        {/* Twitter Card for sharing on Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Betpro Wallet Deposit - Fast and Secure Deposits" />
        <meta name="twitter:description" content="Deposit funds easily and securely into your Betpro Wallet account. Instant transactions and multiple payment methods for fast deposits at Betprowallet.pk." />
        <meta name="twitter:image" content="https://betprowallet.pk/bpthumb1.jpg" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Betpro Wallet Deposit",
        "url": "https://betprowallet.pk/deposit",
        "description": "Deposit funds into your Betpro Wallet account securely. Enjoy fast deposits with multiple payment options for a smooth betting experience.",
        "publisher": {
          "@type": "Organization",
          "name": "Betpro Wallet"
        }
      }
    `}
        </script>
      </Helmet>



      <Header />
      <div className="main-deposit-wraper">
        <div className="container" style={{ padding: "0 1rem", maxWidth: "28rem" }}>
          <div className="middle-depsoit">
            <div className="fieldset-deposit">
              <div className="row">
                <div className="col-12">
                  <h2 className="mt-3 mb-4 text-center">Deposit funds</h2>
                  <h3 className="hh3 mt-3">Payment Method</h3>
                  <hr />
                  <div className="row mt-3 mb-3">

                    {paymentOptions.map((option) => (
                      <div
                        key={option.id}
                        className="col-3 mt-2 paycardgird"
                        onClick={() => handleMethodChange(option.id)} // Make the whole card clickable
                        style={{ cursor: 'pointer' }} // Add cursor pointer for full card
                      >
                        <div className={`deposit-easypaisa  ${selectedPayment === option.id ? 'method-active' : ''}`}>
                          {/* Checkmark, visible only when the payment method is selected */}
                        
                          <label className='label_for_cards' htmlFor={option.id}>
                            <input
                              type="radio"
                              id={option.id}
                              name="payment"
                              value={option.id}
                              checked={selectedPayment === option.id}
                              onChange={() => handleMethodChange(option.id)} // Keeps input checked
                              style={{ display: 'none' }} // Hide the radio button visually
                            />
                            <img src={option.image} alt={option.alt} />
                            <p className={`deposit-text`}>{option.text}</p>
                          </label>


                        </div>
                        <div>

                        </div>
                      </div>

                    ))}

                    
                  </div>
                </div>
              </div>
            </div>
          </div>




          {selectedPayment && (
            <>

              <div className="deposit-amount">


                <p
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px",
                    fontSize: "10px",
                    color: "white"
                  }}
                >
                  <h1 className="title_h1" style={{ margin: "0px 0px 0px 5px" }}>{accountDetails.account_bank_name}</h1>
                  <span style={{ textAlign: "end" }}>Minimum Amount Rs.500</span>
                </p>

                <div className="form-group d-flex align-center amount-box">
                  <div className="width-200">
                    <label htmlFor="enterAmount">Enter amount</label>
                  </div>
                  <input
                    type="number"
                    id="enterAmount"
                    autoFocus
                    className="form-control"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className="d-flex flex-wrap account-details ">
                  <div className="wallet-name width-full">
                    {/* <h1>{accountDetails.account_bank_name}</h1> */}
                  </div>
                  <div className="d-flex flex-wrap width-full align-center h-full">
                    <div className="col-9">
                      <div className="content">
                        <h3>{accountDetails.account_bank_dsc} {accountDetails.account_number}</h3>
                        <h3>{accountDetails.account_title_dsc} {accountDetails.account_title}</h3>
                        {selectedPayment === 3 && (
                          <h3>Bank Name: {accountDetails.account_bank_name}</h3>
                        )}
                      </div>
                    </div>
                    <div className="col-3 d-flex justify-center align-center h-full">
                      <div className="copy-button">
                        <button className="copy_btn" type="button" onClick={copyToClipboard}>
                          <div className="d-flex flex-col justify-center align-center">
                            <img src={Icons.copyIcon} alt="copyIcon" />
                            <p>Copy</p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex align-center payment-proof justify-between h-full py-3">
                  <div className="width-200 overflow-hidden h-full d-flex align-center">
                    <label htmlFor="fileUpload">Send payment proof:</label>
                  </div>
                  <div className="deposit-file-upload">
                    <label className="width-full">
                      <input
                        type="file"
                        className="hidden"
                        hidden="true"
                        onChange={handleFileChange}
                      />
                      <div className="d-flex align-center justify-between width-full">
                        {selectedFile ? (
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Betpro Deposit icon"
                            style={{ width: "20px", height: "20px" }}
                          />
                        ) : (
                          <img src={Icons.fileIcon} alt="Betpro Deposit icon" />
                        )}
                        <p className="choose-file">Choose file</p>
                      </div>
                    </label>
                  </div>
                </div>

              </div>
              <div className="confirm-deposit w-full my-10">
                <button type="submit" onClick={handleSubmit}>Confirm deposit</button>
              </div>





            </>
          )}


             {isPaymentSuccess && (
            <div className="withdraw-modal-container">
              <div className="withdraw-modal">
                <div className="payment-success">
                  <img src={Icons.SuccessIcon} alt="Success Icon" />
                  <h2>Deposit Success!</h2>
                  <p>Rs. {tempAmount}</p>
                  <button onClick={handleSuccessBtnOk}>OK</button>
                </div>
              </div>
            </div>
          )}
          


          <div className="row m-2">

          </div>


        </div>
      </div>


      {/* <Nav /> */}

      <Bottomtab />


      <Loader show={loading} />

      {/* Modal for messages */}
      <Modal show={show} onHide={handleClose} centered className='dialog'>
        <Modal.Header >
          <Modal.Title>Deposit Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Msg}</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>

      </Modal>


    </div>
  );
};

export default Depositpage;

