import React from 'react';
import logo from '../assets/images/logo.png';

function Header() {
  return (
    <header className="App-header bg-dark">
      <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '50px' }}>
        <img 
          src={logo} 
          width="33" 
          height="30" 
          className="mr-2" 
          alt="Betpro Wallet logo" 
        />
        <h3 className="text-white mb-0">Betpro Wallet</h3>
      </div>
    </header>
  );
}

export default Header;
