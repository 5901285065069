import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Nav from '../components/Navigation';
import { FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { GrCopy } from "react-icons/gr";
import Modal from 'react-bootstrap/Modal';
import Bottomtab from '../components/BottomTab'

import { Helmet } from "react-helmet";

import '../assets/css/profile.css'

const ProfilePage = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);
  useEffect(() => {
    if (!user) {
      // console.log("inside ifuser",user);
      navigate('/');
    }
  },);

  const handleGoToWebsite = () => {
    console.log("handle Go To Bet Pro website Button");
  }
  const handleLogout = () => {
    console.log("logout triggered");
    localStorage.removeItem('user');
    console.log("user removed", localStorage.getItem('user'));
    navigate('/', { replace: true });
  }

  const handledepositclick = () => {
    navigate('/deposit')
  }
  const handlewithdrawclick = () => {
    navigate('/withdraw')
  }

  const handleCopyClick = (copyTxt) => {

    setMsg(copyTxt + ' copied successfully');
    handleShow();
  };


  const [show, setShow] = useState(false);
  const [Msg, setMsg] = useState('');

  const handleShow = () => setShow(true);

  const handleClose = function () {
    setShow(false);


  };


  return (
    <div>



      <Helmet>
        {/* Page title optimized for the profile page */}
        <title>Betpro Wallet Profile - Manage Your Account</title>

        <link rel="icon" href="https://betprowallet.pk/favicon.ico" />

        {/* Canonical tag */}
        <link rel="canonical" href="https://betprowallet.pk/profile" />

        {/* Meta description focusing on managing user profiles */}
        <meta name="description" content="Manage your Betpro Wallet profile and account settings. ⚽️ Update your personal information, check your balance, track your bets, and manage withdrawals. Secure and easy profile management at Betprowallet.pk." />

        {/* Meta keywords for search engine optimization */}
        <meta name="keywords" content="Betpro Wallet profile, manage Betpro Wallet account, Betpro Wallet personal information, check balance, track bets, Betpro Wallet withdrawals, Betprowallet.pk profile" />

        {/* Meta author */}
        <meta name="author" content="Betpro Wallet" />

        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Betpro Wallet Profile - Manage Your Account" />
        <meta property="og:description" content="Access and manage your Betpro Wallet profile. Update personal information, view your betting history, check your account balance, and manage your withdrawals." />
        <meta property="og:url" content="https://betprowallet.pk/profile" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://betprowallet.pk/bpthumb1.jpg" />

        {/* Twitter Card for sharing on Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Betpro Wallet Profile - Manage Your Account" />
        <meta name="twitter:description" content="Manage your Betpro Wallet account and update your profile information. Track your bets, check your balance, and manage your withdrawals at Betprowallet.pk." />
        <meta name="twitter:image" content="https://betprowallet.pk/bpthumb1.jpg" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Betpro Wallet Profile - Manage Your Account",
        "url": "https://betprowallet.pk/profile",
        "description": "Manage your Betpro Wallet profile and account. Update personal details, check balance, track bets, and manage withdrawals securely on Betpro Wallet.",
        "publisher": {
          "@type": "Organization",
          "name": "Betpro Wallet"
        }
      }
    `}
        </script>
      </Helmet>


      <Header />





      

      <div className="login-wrapper">
        <div className="main-login">
          <div className="container" style={{ padding: "0 1rem", maxWidth: "28rem" }}>

            <div className="row align-items-top">
              <div className="col-12">
                <div className="left-login-box1">
                  <div className="fieldset">
                    <div className="title mb-4">

                      <div className="account3 mt-3">
                        <h3>Personal profile</h3>
                        <div className="info mt-3 mb-3">
                          <p>
                            <strong className="mb-3 ">Name:</strong>
                            <span className="mb-3 right">{parsedUser.name}</span>
                          </p>
                          <p>
                            <strong className="mb-3 ">Email:</strong>
                            <span className="mb-3 right">{parsedUser.email}</span>
                          </p>
                          <p>
                            <strong className="mb-3 ">Whatappp Number</strong>
                            <span className="mb-3 right">{parsedUser.number}</span>
                          </p>

                        </div>


                       
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>

            <div className="confirm-deposit w-full my-10 mt-3">
                <button type="submit" onClick={handleLogout}>Logout</button>
              </div>


          </div>
        </div>
      </div>
     
     

      {/* <Nav /> */}

      <Bottomtab />

      {/* Modal for messages */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Msg}</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>

      </Modal>

    </div>
  )
}

export default ProfilePage;
