import { useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../content/Icons";
import "../assets/css/BottomTab.css";
import { useState, useEffect } from "react";
import axios from "axios";

const BottomTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [weburl, setWebUrl] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState('');


 

  const pathParts = location.pathname.split('/');
  const activeTab = pathParts[1] || ""; // This will be "home", "history", etc.


  const fetchUrl = async () => {
    const params = new URLSearchParams();
    params.append("table_name", "status");

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post(
        "https://api.betprowallet.pk/ft_status.php",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          cancelToken: source.token,
        }
      );

      clearTimeout(timeout);

      if (
        response.data &&
        typeof response.data === "string" &&
        response.data.startsWith("0 results")
      ) {
        return null;
      }

      if (response.data.length > 0) {

        if (response.data[0].status_on === "1") {

          setWhatsappNumber(response.data[0].status_msg);

        } else {
          setWhatsappNumber('');
        }



       
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching user data", error);
      }
    }
  };



  const handlewhatsappclick = () => {


    if (whatsappNumber.trim() !== '') {
      const formattedNumber = whatsappNumber.replace(/\D/g, ''); // Remove any non-numeric characters
      const whatsappLink = `https://wa.me/${formattedNumber}`; // Create the WhatsApp link

      // Redirect to the WhatsApp link
      window.open(whatsappLink, '_blank');
    } else {
      console.log('WhatsApp number is empty');
      // Optionally, you can show an error message to the user here
    }

  }

  useEffect(() => {
    fetchUrl();

     setWebUrl("https://bpexch.com/Users/Login");

  }, []);

  const handleNavigate = (path) => {
    if (path === "/bpro") {
      // For "bpro", redirect either to YouTube or to the fetched URL based on user status
      window.location.href = weburl;
    } 
    else {
      navigate(`${path}`);
      console.log(`${path}`);
    }
  };

  return (
    <div className="bottom-tab-container">
      <div className="bottom-tab-inner">
        {/* Main Button */}
        <button
          onClick={() => handleNavigate("/home")}
          className={`bottom-tab-button ${activeTab === "home" ? "active" : ""}`}
        >
                 <div className="bottom-tab-img-container mb-2">
            <img
              src={activeTab === "home" ? Icons.activeMainIcon : Icons.mainIcon}
              alt="Main Icon"
            />
          </div>
          <p>Home</p>
        </button>

        {/* History Button */}
        <button
          onClick={() => handleNavigate("/transactions")}
          className={`bottom-tab-button ${activeTab === "transactions" ? "active" : ""}`}
        >
          <div className="bottom-tab-img-container mb-2">
            <img
              src={
                activeTab === "transactions" ? Icons.activeHistoryIcon : Icons.historyIcon
              }
              alt="History Icon"
            />
          </div>
          <p>History</p>
        </button>

        {/* Deposit Button */}
        <button
          onClick={() => handleNavigate("/deposit")}
          className={`bottom-tab-button ${activeTab === "deposit" ? "active" : ""}`}
          style={{ position: "relative" }}
        >
          <div className="deposit-icon-container">
            <img src={Icons.depositIcon} alt="Deposit Icon" />
          </div>
          {/* This empty div maintains vertical spacing */}
          <div className="bottom-tab-img-container mb-1" style={{ height: "17px" }}></div>
          <p>Deposit</p>
        </button>

        {/* Bpro Button */}
        <button
          onClick={() => handleNavigate("/bpro")}
          className={`bottom-tab-button ${activeTab === "bpro" ? "active" : ""}`}
        >
          <div className="bottom-tab-img-container mb-2">
            <img
              src={activeTab === "bpro" ? Icons.activeBproIcon : Icons.broIcon}
              alt="Bpro Icon"
            />
          </div>
          <p>Betpro</p>
        </button>

        {/* Menu Button */}
        <button
          onClick={() => handleNavigate("/profile")}
          className={`bottom-tab-button ${activeTab === "profile" ? "active" : ""}`}
        >
          <div className="bottom-tab-img-container mb-2">
            <img
              src={activeTab === "profile" ? Icons.activeMenuIcon : Icons.menuIcon}
              alt="Menu Icon"
            />
          </div>
          <p>Profile</p>
        </button>
      </div>

      <div className="whatsapp-container">
  <img src={Icons.whatsapp_icon} alt="WhatsApp" onClick={handlewhatsappclick} />
</div>

    </div>
    


  );
};



export default BottomTab;
