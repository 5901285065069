import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Header from '../components/header';
import Footer from '../components/footer';
import MainSection from '../components/MainSection';
import { Helmet } from "react-helmet";
import { color } from 'framer-motion';


const AdsDialog = ({ dialogShow, url_img, onClose }) => {
    if (dialogShow !== 1) return null;
  
    return (
      <div style={styles.overlay}>
        <div style={styles.dialog}>
          <button style={styles.closeButton} onClick={onClose}>
            ×
          </button>
          <img src={url_img} alt="Ad" style={styles.image} />
        </div>
      </div>
    );
  };


const LoginPage = () => {


    const [dialogShow, setDialogShow] = useState(0);
    const [url_img, setUrl_img] = useState('');

    const [oneTime, setOneTime] = useState(true);

    const [emailOrPhone, setEmailOrPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            navigate('/home');
        }
    }, [navigate]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePassClose = () => setShowPasswordModal(false);
    const handlePassShow = () => setShowPasswordModal(true);




    const fetchUrl = async () => {
        const params = new URLSearchParams();
        params.append("table_name", "status");
      
        try {
          const source = axios.CancelToken.source();
          const timeout = setTimeout(() => {
            source.cancel();
          }, 20000); // 20 seconds timeout
      
          const response = await axios.post(
            "https://api.betprowallet.pk/ft_status.php",
            params,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              cancelToken: source.token,
            }
          );
      
          clearTimeout(timeout);
      
          if (
            response.data &&
            typeof response.data === "string" &&
            response.data.startsWith("0 results")
          ) {
            return null;
          }
      
          if (response.data.length > 0) {
      
            if (response.data[0].dialogShow_off ) {


      if(oneTime){
              setDialogShow(Number(response.data[0].dialogShow_off));
              setUrl_img(response.data[0].img_ads_url);
              setOneTime(false)
      }
    
              
            } else {
              setDialogShow(0);
            }
      
      
      
      
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          } else {
            console.error("Error fetching user data", error);
          }
        }
      };


    useEffect(() => {
    
        fetchUrl();
    
      });
      
    
    


    const validateInput = (emailOrPhone, password) => {
        if (emailOrPhone.length === 11 && password !== "") {
            if (password.length >= 7) {
                return true; // Valid number and password
            } else {
                setError('Password is incorrect. It must be at least 7 characters long.');
                return false;
            }
        } else if (emailOrPhone.includes('@') && password !== "") {
            if (emailOrPhone.includes('.com') || emailOrPhone.includes('.Com') || emailOrPhone.includes('.COM')) {
                if (emailOrPhone.includes('gmail') || emailOrPhone.includes('Gmail') || emailOrPhone.includes('GMAIL')) {
                    if (password.length >= 7) {
                        return true; // Valid email and password
                    } else {
                        setError('Password is incorrect. It must be at least 7 characters long.');
                        return false;
                    }
                } else {
                    setError('Invalid email domain.');
                    return false;
                }
            } else {
                setError('Invalid email format.');
                return false;
            }
        } else {
            if (emailOrPhone.length >= 11 || emailOrPhone.includes('@')) {
                setError('Invalid number or email format.');
            } else if (password === "" || password.length < 7) {
                setError('Password is required and must be at least 7 characters long.');
            } else if (!emailOrPhone.includes('@')){
                setError('Invalid number or email format.');
            }
            return false;
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        if (!validateInput(emailOrPhone, password)) {
            setLoading(false);
            handleShow();
            return;
        }

        const userField = emailOrPhone.length === 11 && !emailOrPhone.includes('@') ? 'user_number' : 'user_email';

        const params = new URLSearchParams();
        params.append('username', emailOrPhone);
        params.append('real_pass', password);
        params.append('fieldName', userField);
        params.append('table_name', 'users');

        try {
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 20000); // 20 seconds timeout

            const response = await axios.post('https://api.betprowallet.pk/loginUserApi_ft.php', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                cancelToken: source.token,
            });

            clearTimeout(timeout);


            if (response.data.message === '0 results') {
                setError('Your email or phone number, or password is incorrect. Please try again.');
                handleShow();
            } else {


                

                // Check if response starts with "0 results"
                if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
                    setError('User not found. Please try again.');
                    handleShow();
                } else {



                    if(response.data[0].user_blocked === '0'){

                    localStorage.removeItem('user');

                    const user = {
                        name: response.data[0]?.user_fullname || 'User',
                        bp_username: response.data[0]?.user_betpro_username || '',
                        bp_pass: response.data[0]?.user_betpro_password || '',
                        email: response.data[0]?.user_email || '',
                        password: '',
                        number: response.data[0]?.user_whatsapp || 'N/A',
                        userID: response.data[0]?.user_id || 'N/A',
                        userActive: response.data[0]?.user_active || 0,
                        withdrawAmount:response.data[0]?.withdraw_amount || 25000,
                    };




                    localStorage.setItem('user', JSON.stringify(user));
                    navigate('/home');

                } else {

                    setError('User not found. Your email, phone number or password is incorrect.');
                    handleShow();

                }


                }



            }

        } catch (error) {
            if (axios.isCancel(error)) {
                setError('Request timed out. Please try again.');
            } else {
                setError('Please check your internet connection...');
            }
            handleShow();

            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = (e) => {
        e.preventDefault();
        handlePassClose();
        console.log('Password reset email should be sent here.');
    };

    return (
        <div>

{/* Use Helmet to dynamically set the title and meta description */}
{/* <Helmet> */}
  {/* Page title with relevant keywords */}
  {/* <title>Betpro Wallet Login - Bpexch</title> */}

  {/* <link rel="icon" href="https://betprowallet.pk/favicon.ico" /> */}


   {/* Canonical tag */}
   {/* <link rel="canonical" href="https://betprowallet.pk/" /> */}
  
  {/* Meta description to summarize the page content with optimized keywords */}
  {/* <meta name="description" content="Betpro Wallet mobile login✔️ Secure Betpro Wallet login ⚽️ Quick access to Betpro Wallet APP login ✅ Sign in to bet and win real money with Betprowallet.pk. Fast withdrawals and 24/7 customer support." /> */}
  
  

  {/* Meta keywords to target top-ranking search queries */}
  {/* <meta name="keywords" content="Betpro Wallet login, Betpro Wallet sign in, Betpro Wallet mobile login, Betpro Wallet APP login, Betpro Wallet account access, secure login, online betting in Pakistan, Betprowallet.pk login" /> */}
  
  {/* Meta author */}
  {/* <meta name="author" content="Betpro Wallet" /> */}


  
  {/* Open Graph meta tags for social media sharing */}
  {/* <meta property="og:title" content="Betpro Wallet Login - Bpexch" />
  <meta property="og:description" content="Sign in to Betpro Wallet securely. Quick access to Betpro Wallet APP login and start betting on sports today with fast payouts and bonuses." />
  <meta property="og:url" content="https://betprowallet.pk" />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://betprowallet.pk/bpthumb1.jpg" /> */}
  
  {/* Twitter Card for better social sharing */}
  {/* <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Betpro Wallet Login - Bpexch" />
  <meta name="twitter:description" content="Secure Betpro Wallet login. Access your account via mobile or app and bet on your favorite sports with Betpro Wallet. Sign in now at Betprowallet.pk!" />
  <meta name="twitter:image" content="https://betprowallet.pk/images/login-twitter-image.jpg" /> */}
  
  {/* Structured Data for SEO */}
  {/* <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Betpro Wallet Login - Bpexch",
        "url": "https://betprowallet.pk",
        "description": "Betpro Wallet Sign In page. Log in securely and access your betting account to place bets, win real money, and get fast payouts.",
        "publisher": {
          "@type": "Organization",
          "name": "Betpro Wallet"
        }
      }
    `}
  </script> */}
{/* </Helmet> */}

            <Header />
            <div className="login-wrapper2">
                <div className="main-login">
                    <div className="container" style={{ padding: "0 1rem", maxWidth: "28rem" }}>
                        <div className="row align-items-top">
                            <div className="col-md-12">
                                <div className="left-login-box">
                                    <div className="fieldset"

                                        style={{
                                            padding: '10px 0px',
                                        }} >
                                        <div className="title mb-5">
                                            <h2>Login to Betpro wallet</h2>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-12">
                                                <div className="inputs field-container">
                                                    <form onSubmit={handleSubmit}>
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            className="mb-4"
                                                            placeholder="Email or phone number"
                                                            value={emailOrPhone}
                                                            onChange={(e) => setEmailOrPhone(e.target.value)}
                                                            required
                                                        />
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            className="mb-4"
                                                            placeholder="Enter password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            required
                                                        />
                                                        <button type="submit" className="btn-signin mb-3" disabled={loading}>
                                                            {loading ? 'Loading...' : 'Sign in'}
                                                        </button>
                                                    </form>
                                                    <div className="forgot-password">
                                                        <a onClick={handlePassShow}>Forgot password?</a>
                                                    </div>
                                                    <a href="/signup" className="signup mb-3">
                                                        Don't have an account?
                                                    </a>
                                                    <button className="btn-create-account mb-3" onClick={() => navigate('/signup')}>
                                                        Create account
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           { Number(dialogShow) == 1 ?
           <>
            <AdsDialog dialogShow={dialogShow} url_img={url_img} onClose={() => setDialogShow(0)} />  </> : null

           }

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header >
                    <Modal.Title>Sign in Failed!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{error}</p>
                    <hr />
                    <p onClick={handleClose} className="model-ok">Ok</p>
                </Modal.Body>
            </Modal>

            <Modal show={showPasswordModal} onHide={handlePassClose} centered>
                <Modal.Header >
                    <Modal.Title >Forgot Password?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You will receive instructions for resetting your password.</p>
                    <form onSubmit={handleForgotPassword}>
                        <div className='forgotinputs'>
                            <input type="text" placeholder="Email or phone number" required />
                            <button type="submit" className="btn-signin" style={{ marginTop: "15px" }}>Send my Password</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <MainSection />
            <Footer />
        </div>
    );
};


const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex:"10000"
    },
    dialog: {
      position: "relative",
      backgroundImage:"linear-gradient(#2E506C, #152337)",
      padding: "20px 15px",
      borderRadius: "8px",
      textAlign: "center",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    closeButton: {
      position: "absolute",
      top: "0px",
      right: "15px",
      fontSize: "30px",
      background: "none",
      color:"white",
      
      border: "none",
      cursor: "pointer",
    },
    image: {
        marginTop:"20px",
      width: "100%",
      height: "auto",
      maxWidth: "300px",
      borderRadius: "8px",
    },
    button: {
      padding: "10px 20px",
      fontSize: "16px",
      cursor: "pointer",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "5px",
    },
  };
  

export default LoginPage;
