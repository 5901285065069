import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import download from '../assets/images/download.png';
import cricket from '../assets/images/cricket.png';
import { FaWhatsapp } from 'react-icons/fa';
import Nav from '../components/Navigation';
import Bottomtab from '../components/BottomTab'
import { useNavigate } from "react-router-dom";
import MainSection from "../components/MainSection";
import axios from 'axios';
import { Icons } from '../content/Icons';
import { GrCopy } from "react-icons/gr";
import Modal from 'react-bootstrap/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from "react-helmet";


const Homepage = () => {
  const navigate = useNavigate();

  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);

  const [whatsappNumber, setWhatsappNumber] = useState('');




  const fetchwhatsappnum = async () => {
    const params = new URLSearchParams();
    params.append('table_name', 'status');

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post('https://api.betprowallet.pk/ft_status.php', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      });

      clearTimeout(timeout);

      // Check if response starts with "0 results"
      if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
        // Handle user not found scenario

        return null; // or handle it as needed
      }

      // Proceed if user data is available
      if (response.data.length > 0) {


        if (response.data[0].status_on === "1") {

          setWhatsappNumber(response.data[0].status_msg);

        } else {
          setWhatsappNumber('');
        }

      }

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error fetching user data', error);
      }
    }
  };


  fetchwhatsappnum();

  const fetchUserData = async () => {
    const params = new URLSearchParams();
    params.append('username', parsedUser.email);
    params.append('fieldName', 'user_email');
    params.append('table_name', 'users');

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post('https://api.betprowallet.pk/loginUserApi_ft.php', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      });

      clearTimeout(timeout);

      // Check if response starts with "0 results"
      if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
        // Handle user not found scenario

        return null; // or handle it as needed
      }

      // Proceed if user data is available
      if (response.data.length > 0) {

         if(response.data[0].user_blocked === '0'){

        const newUser = {
          name: response.data[0]?.user_fullname || 'User',
          bp_username: response.data[0]?.user_betpro_username || '',
          bp_pass: response.data[0]?.user_betpro_password || '',
          email: response.data[0]?.user_email || '',
          password: '',
          number: response.data[0]?.user_whatsapp || 'N/A',
          userID: response.data[0]?.user_id || 'N/A',
          userActive: response.data[0]?.user_active || 0,
          withdrawAmount:response.data[0]?.withdraw_amount || 25000,
        };

        const existingUser = JSON.parse(localStorage.getItem('user'));


        
       


        // Compare new user data with existing user data
        if (localStorage.getItem('user') && JSON.stringify(existingUser) !== JSON.stringify(newUser)) {

          // Update local storage
          localStorage.setItem('user', JSON.stringify(newUser));
          // Refresh the page
          window.location.reload();
        }else if (!(localStorage.getItem('user'))){
         
        
         
        }

       

      } else {
        localStorage.removeItem('user');
        navigate('/');
      }
    }

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error fetching user data', error);
      }
    }
  };

  // Call fetchUserData periodically
  const startUserListener = () => {



    checkUser(); // Initial fetch
    const intervalId = setInterval(checkUser, 5000); // Check every 30 seconds

    // Cleanup on component unmount or when you no longer need the listener

    return () => clearInterval(intervalId);

  

  };


 const checkUser = ()=> {

  if(user){

   
    fetchUserData();

  }


 }

 




  useEffect(() => {

    if (!user) {
      navigate('/');
    } else {

      startUserListener();

       // Use this function to start the listener when needed (e.g., on component mount)
 


    }
  }, [navigate]);

  const handleGoToWebsite = () => {
    console.log("handle Go To Bet Pro website Button");
  }

  const handledepositclick = () => {
    navigate('/deposit')
  }


  const handlewhatsappclick = () => {


    if (whatsappNumber.trim() !== '') {
      const formattedNumber = whatsappNumber.replace(/\D/g, ''); // Remove any non-numeric characters
      const whatsappLink = `https://wa.me/${formattedNumber}`; // Create the WhatsApp link

      // Redirect to the WhatsApp link
      window.open(whatsappLink, '_blank');
    } else {
      console.log('WhatsApp number is empty');
      // Optionally, you can show an error message to the user here
    }

  }

  const handlewithdrawclick = () => {
    navigate('/withdraw')
  }


  const handleCopyClick = (copyTxt) => {

    setMsg(copyTxt + ' copied successfully');
    handleShow();
  };


  const [show, setShow] = useState(false);
  const [Msg, setMsg] = useState('');

  const handleShow = () => setShow(true);

  const handleClose = function () {
    setShow(false);


  };


  return (
    <div>

      {/* Use Helmet to dynamically set the title and meta tags */}
      <Helmet>
        <title>Betpro Wallet - Best Online Betting Platform in Pakistan</title>
        <link rel="icon" href="https://betprowallet.pk/favicon.ico" />

   {/* Canonical tag */}
   <link rel="canonical" href="https://betprowallet.pk/home" />

        <meta name="description" content="Betpro Wallet is the best online betting platform in Pakistan. ⚽️ Sign up for free, bet on sports, and get a sign-up bonus. ✅ Easy deposits, secure withdrawals, and win big today at Betprowallet.pk." />
        <meta name="keywords" content="Betpro Wallet, online betting in Pakistan, sports betting, Betpro Wallet Pakistan, best betting platform, Betpro Wallet registration, Betpro Wallet sign up bonus, Betprowallet.pk" />
        <meta name="author" content="Betpro Wallet" />
        <meta property="og:title" content="Betpro Wallet - Best Online Betting Platform in Pakistan" />
        <meta property="og:description" content="Join Betpro Wallet, Pakistan's most trusted betting platform. Bet on sports, enjoy bonuses, and win big with safe and easy transactions." />
        <meta property="og:url" content="https://betprowallet.pk.home" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://betprowallet.pk/bpthumb1.jpg" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Website",
              "name": "Betpro Wallet - Best Online Betting Platform in Pakistan",
              "url": "https://betprowallet.pk/home",
              "description": "Betpro Wallet is the leading online betting platform in Pakistan. Sign up today and get a welcome bonus to start betting on your favorite sports.",
              "publisher": {
                "@type": "Organization",
                "name": "Betpro Wallet"
              }
            }
          `}
        </script>
      </Helmet>


      <Header />
      <div className="login-wrapper2">
        <div className="main-login">
          <div className="container" style={{ padding: "0 1rem", maxWidth: "28rem" }}>


            <div className="row align-items-top">
              <div className="col-md-12">
                <div className="left-login-box1">
                  <div className="fieldset">
                    <div className="title mb-4">





                      {parsedUser.userActive == 1 ? (
                        <>
                          <h2 className="mt-3">My Betpro account</h2>
                        </>
                      ) : (
                        <>
                          <h2 className="mt-3">Active Betpro account</h2>
                        </>
                      )}



                      {parsedUser.userActive == 1 && (
                        <>

                          <div className="account3 mt-3">
                            <div className="whatappp mt-3 ">
                              <div className="btn-active-account2">
                                <CopyToClipboard text={parsedUser.bp_username}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h5 style={{ margin: 0 }}>
                                      Username: <span className="ic-copy" style={{ marginLeft: '4px' }}>{parsedUser.bp_username}</span>
                                    </h5>
                                    <GrCopy className="icon" onClick={() => handleCopyClick('Betpro username')} style={{ marginLeft: "10px" }} />
                                  </div>
                                </CopyToClipboard>
                              </div>




                            </div>
                            <div className="whatappp ">

                              <div className="btn-active-account2">
                                <CopyToClipboard text={parsedUser.bp_pass}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h5 style={{ margin: 0 }}>
                                      Password: <span className="ic-copy" style={{ marginLeft: '4px' }}>{parsedUser.bp_pass}</span>
                                    </h5>
                                    <GrCopy className="icon" onClick={() => handleCopyClick('Betpro password')} style={{ marginLeft: "10px" }} />
                                  </div>
                                </CopyToClipboard>
                              </div>

                            </div>
                          </div>

                        </>)}

                      {parsedUser.userActive == 0 && (
                        <>
                          {/* <div className="account mt-3">
                            <h3 className="text-center  mb-3"> ضروری ہدایات</h3>


                            <ul>
                              <li className="mb-3">  ڈپازٹ کرنے کے بعد آپ کا Betpro اکاؤنٹ ایکٹیو ہو جائے گا۔</li>
                              <li className="mb-3"> <>Betpro کا username اور password </>یہاں پر ظاہر ہوگا۔</li>
                              <li className="mb-3">ڈپازٹ کی گئی رقم آپ کی Betpro اکاؤنٹ میں شامل کی جائے گی۔ </li>
                            </ul>
                          </div> */}


                       <div className="user-instructions">
                        <h2 className='user-title pb-4'>New user instructions</h2>
                     
                        <div className="flex items-center pb-3">
                            <div className='pr-2'>
                                <img src={Icons.icon1} alt="infoIcon" className='instruction-icon' />
                            </div>
                            <div>
                                <p className='instruction-text' style={{ lineHeight: '12.1px' }}>1st deposit karne per apka Betpro account active hojae ga or ID & password uper show hojae ge.</p>
                            </div>
                        </div>
                        <div className="flex items-center pb-3">
                            <div className='pr-2'>
                                <img src={Icons.icon2} alt="infoIcon" className='instruction-icon' />
                            </div>
                            <div>
                                <p className='instruction-text' style={{ lineHeight: '12.1px' }}>Deposit ki gayi raqam apke Betpro account me add hojae gi.</p>
                            </div>
                        </div>

                        <div className="flex items-center pb-3">
                            <div className='pr-2'>
                                <img src={Icons.icon3} alt="infoIcon" className='instruction-icon' />
                            </div>
                            <div>
                                <p className='instruction-text' style={{ lineHeight: '12.1px' }}>Deposit karne ke 10 minutes baad apki id activate ho jaye gi.</p>
                            </div>
                        </div>
                    </div>


                        </>)}

                      <div className="withdraw mt-4 mb-3">
                        <button className="col-6 cus-btn3 btn btn-default " onClick={handledepositclick}>
                          <h5>
                            <span className=""></span>+Deposit
                          </h5>
                        </button>
                        <button className="col-6 cus-btn3 btn btn-default" onClick={handlewithdrawclick}>
                          <h5>
                            <span className=""></span>Withdraw
                          </h5>
                        </button>
                      </div>

                      {/* <div className="whatappp  mb-3 text-center">
                        <button className="btn-whatappp" onClick={handlewhatsappclick}><h5><FaWhatsapp style={{ color: 'green', fontSize: '1.5em', marginRight: '3px' }} />Contact on Whatapp</h5></button>
                      </div> */}

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


      <MainSection />

      {/* <Nav /> */}

      <Bottomtab/>

      {/* Modal for messages */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Msg}</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>

      </Modal>

    </div>
  )
}

export default Homepage;
