import React, { useEffect, useState } from 'react';
import apk from '../assets/images/apk.png';
import google from '../assets/images/google.png';
import easypaisa from '../assets/images/mob/easypaisa.png';
import jazzcash from '../assets/images/mob/jazzcash.png';
import bank from '../assets/images/mob/bank.png';
import bitcoin from '../assets/images/mob/bitcoin.png';
import '../assets/css/style.css';
import bottom_bar_icons from '../assets/images/mob/bottom_bar_icons.png';

import axios from "axios";
import { Icons } from "../content/Icons";








function Footer() {


  const [whatsappNumber, setWhatsappNumber] = useState('');


  const fetchUrl = async () => {
    const params = new URLSearchParams();
    params.append("table_name", "status");
  
    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout
  
      const response = await axios.post(
        "https://api.betprowallet.pk/ft_status.php",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          cancelToken: source.token,
        }
      );
  
      clearTimeout(timeout);
  
      if (
        response.data &&
        typeof response.data === "string" &&
        response.data.startsWith("0 results")
      ) {
        return null;
      }
  
      if (response.data.length > 0) {
  
        if (response.data[0].status_on === "1") {
  
          setWhatsappNumber(response.data[0].status_msg);
  
        } else {
          setWhatsappNumber('');
        }
  
  
  
  
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching user data", error);
      }
    }
  };
  
  
  
  
  
  
  
  useEffect(() => {
  
    fetchUrl();
  
  
  
  }, []);
  
  
  
  const handlewhatsappclick = () => {
  
  
    if (whatsappNumber.trim() !== '') {
      const formattedNumber = whatsappNumber.replace(/\D/g, ''); // Remove any non-numeric characters
      const whatsappLink = `https://wa.me/${formattedNumber}`; // Create the WhatsApp link
  
      // Redirect to the WhatsApp link
      window.open(whatsappLink, '_blank');
    } else {
      console.log('WhatsApp number is empty');
      // Optionally, you can show an error message to the user here
    }
  
  }



  return (
    <div className="footer-wrapper">
      <div className="">
        <div className="row">

          {/* <div className="col-lg-6 col-md-6 col-sm-6 col-6 ">
            <h2 className="footer-hed">BetproWallet.pk</h2>
            <div className="row">
              <div className="col-md-4 col-sm-12 mt-5">
                <div className="image-wrapper-left">
                  <img src={apk} alt='APK img' />
                </div>
              </div>
              <div className="col-md-8 col-sm-12 mt-5">
                <div className="image-wrapper-left">
                  <img src={google} alt='play store img' />
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg col-md-6 col-sm-6 col-6">
            <h2 className="footer-hed" >Payment Methods</h2>
            <div className="row">
              <div className="col-md col-sm-6 mt-5"> <div className="image-wrapper-right">
                <img src={easypaisa} alt='easypaisa img' />
              </div></div>
              <div className="col-md col-sm- mt-5"> <div className="image-wrapper-right">
                <img src={jazzcash} alt='jazzcash img' />
              </div></div>
              <div className="col-md col-sm-6 mt-5"> <div className="image-wrapper-right">
                <img src={bank} alt='bank img' />
              </div></div>
              <div className="col-md col-sm-6 mt-5"> <div className="image-wrapper-right">
                <img src={bitcoin} alt='bitcoin img' />
              </div></div>
            </div>

          </div> */}


          <div className="col-lg ">
            <h2 className="footer-hed" >Payment Methods</h2>
            <div className="row">
              <div className="col  mt-2"> <div className="image-wrapper-right">
                <img src={bottom_bar_icons} alt='easypaisa img' />
              </div></div>

            </div>

          </div>

        </div>
        <hr />
        <div className="row">
          <div className="col-md-12 text-center cpoyright">
            <p>Copyright © 2025 Developed by Betpro Exchange</p>
          </div>
        </div>
        <div className="whatsapp-container2">
        <img src={Icons.whatsapp_icon} alt="WhatsApp" onClick={handlewhatsappclick} />
      </div>
      </div>


     

    </div>

  );
}

export default Footer;
