import React, { useEffect, useState } from 'react';
import download from '../assets/images/download.png';

function MainSection() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallPromptVisible, setIsInstallPromptVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallPromptVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleDownloadClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    } else {
      // If the PWA prompt isn't available, you can navigate to an alternative download link
      // window.location.href = 'URL to your APK or download page';
    }
  };

  return (
    <div className="main-download">
      <div className="container text-center" style={{ padding: "0 1rem", maxWidth: "28rem",marginBottom:"150px" }}>
      <div className="wallet2">
        <h2 className="hh1 mb-1">Download Betpro Wallet</h2>
        <p className="hh2 mb-2">On your phone today</p>
       
        <p>
        Betpro Wallet APP is an Android earning app that provides the best platform for users to start earning money right away. 
        </p>
        <button className="btn-download mb-3" onClick={handleDownloadClick}>
          <img src={download} alt="Download" className="btn-icon" /> Download Now
        </button>
      </div>
      </div>
    </div>
  );
}

export default MainSection;
