import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Nav from "../components/Navigation";
import easypaisa from "../assets/images/deposit/easypaisa.png";
import jazzcash from "../assets/images/deposit/jazzcash.png";
import bank from "../assets/images/deposit/bank.png";
import upaisa from "../assets/images/deposit/upaisa.png";
import nayapay from "../assets/images/deposit/nayapay.png";
import { useNavigate } from "react-router-dom";
import tick from '../assets/images/topTick.svg';
import axios from 'axios';



import { Icons } from '../content/Icons';
import Bottomtab from '../components/BottomTab'
import Modal from 'react-bootstrap/Modal';
import { sendFCMNotification } from '../components/sendnoti';


import { Helmet } from "react-helmet";

import Loader from './Loader';
import '../assets/css/withdraw.css'


const Withdrawpage = () => {




  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  const parsedUser = JSON.parse(user);
  useEffect(() => {





    if (!user) {
   navigate('/');
      
    } else {
    
    }
  }, [navigate]);


  const [dialogBackONoFF, setDialogOnOff] = useState(false);
  const [dialogToDeposit, setDialogToDeposit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tempAmount, setTempAmount] = useState('');

  const [withdrawTxt, setWithdrawTxt] = useState(0);

    const [WithdrawOnOFF, setWithdrawOnOFF] = useState(0);



  const fetchUrl = async () => {
    const params = new URLSearchParams();
    params.append("table_name", "status");
  
    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout
  
      const response = await axios.post(
        "https://api.betprowallet.pk/ft_status.php",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          cancelToken: source.token,
        }
      );
  
      clearTimeout(timeout);
  
      if (
        response.data &&
        typeof response.data === "string" &&
        response.data.startsWith("0 results")
      ) {
        return null;
      }
  
      if (response.data.length > 0) {
  
        if (response.data[0].withdraw_off ) {
  
          setWithdrawOnOFF(response.data[0].withdraw_off);

          
        } else {
          setWithdrawOnOFF('');
        }
  
  
  
  
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching user data", error);
      }
    }
  };
  




  const options = [
    { value: '1000', label: '1000' },
    { value: '2000', label: '2000' },
    { value: '5000', label: '5000' },
    { value: '10000', label: '10000' },
  ];

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [amount, setAmount] = useState("");
 
  const handleChange = (event) => {
    setAmount(event.target.value);
  };
  const [selectedPrice, setSelectedPrice] = useState('');

  const handleClick = (price) => {
    setSelectedPrice(price);
    setAmount(price);
  };
  useEffect(() => {
  }, [selectedPrice]);

  const paymentOptions = [
    { id: 1, image: Icons.easypaisaIcon, alt: 'Easypaisa', text: 'Easypaisa' },
    { id: 2, image: Icons.JazzCash, alt: 'JazzCash', text: 'Jazz Cash' },
    { id: 3, image: Icons.BankTransfer, alt: 'Bank', text: 'Bank' },
    { id: 6, image: Icons.raast, alt: 'Raast', text: 'Raast' },
    { id: 4, image: Icons.binance, alt: 'Binance', text: 'Binance' },
    { id: 5, image: Icons.skrill, alt: 'Skrill', text: 'Skrill' },

  ];

  const placeholder = [
    {  title: 'Account No', name: 'Account Name' },
    {  title: 'Account No', name: 'Account Name' },
    {  title: 'Account No', name: 'Account Name' },
    {  title: 'Binance Email or ID', name: 'Binance Name' },
    {  title: 'Skrill Email', name: 'Skrill ID' },
    {  title: 'Raast Acc No', name: 'Raast Acc Name' },

  ];

  const handleSuccessBtnOk = () => {
    setShow(false);
    setLoading(false);

    setIsPaymentSuccess(false);
    navigate('/');



}


  const [selectedPayment, setSelectedPayment] = useState(null);

  const handleMethodChange = (optionId) => {
    setSelectedPayment(optionId);
   
    if (!(optionId === 3)) {
      setBankName(null);
    }
  };
  useEffect(() => {

    fetchUrl();

  }, [selectedPayment]);

  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [bankName, setBankName] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleNumberChange = (event) => {
    const newValue = event.target.value;
    setNumber(newValue);
  };


  const handleBankChange = (event) => {
    const newValue = event.target.value;
    setBankName(newValue);
  };

  const [show, setShow] = useState(false);
  const [Msg, setMsg] = useState('');
  const handleClose = function () {
    setShow(false);
    setLoading(false);

    if (dialogBackONoFF) {
      navigate('/');
    }
   
    if(dialogToDeposit){
      navigate('/deposit');
    }

    setDialogOnOff(false);
    setDialogToDeposit(false);
  };

  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!selectedPayment) {
      setMsg('Please select a Payment Option to proceed')
      handleShow();
    }
    const selectedPaymentOption = paymentOptions.find(option => option.id === selectedPayment).text;

    // const formData = {
    //   amount,
    //   selectedPrice,
    //   selectedPaymentOption,
    //   name,
    //   number,
    //   bankName,
    // };
    // console.log("Form Data:", formData);

     if(Number(WithdrawOnOFF) == 1
    ){

      setWithdrawTxt(1);

       setTimeout(() => setWithdrawTxt(0), 3000); 

       setLoading(false)
       return;
     }

    if (amount && parsedUser.withdrawAmount && !isNaN(amount) && !isNaN(parsedUser.withdrawAmount) && !(Number(amount) >= 1000 && Number(amount) <= Number(parsedUser.withdrawAmount))) {

      if (amount && parsedUser.withdrawAmount && !isNaN(amount) && !isNaN(parsedUser.withdrawAmount) && Number(amount) > Number(parsedUser.withdrawAmount)) {
        console.log(typeof amount, amount); // Should print: number 999
console.log(typeof parsedUser.withdrawAmount, parsedUser.withdrawAmount); // Check if it's a string
      setMsg('Withdraw Amount Limit Exceed Please Contact on Helpline.');
      handleShow();
      return;
      }
   
      setMsg('Min amount is 1000. Please enter the correct amount.');
      handleShow();
    }
    else if (!number || !name) {
      setMsg('please enter valid account details.')
      handleShow();
    }
    else if (!bankName && selectedPayment === 3) {
      setMsg('please enter valid account details.')
      handleShow();
    }

    // else if(!number){
    //   setMsg('You have made too many requests recently please wait and try again later.')
    //   handleShow();
    // }
    // else if(){
    //   setMsg('Sorry, you have reached your daily withdrawal limit of Rs.15,000');
    //   handleShow();

    // }else if(){
    //   setMsg('You have already pending withdrawl request please wait');
    //   handleShow();
    // }else if(){
    //   setMsg('You don’t have an active BetPro account. Please deposit funds and activate your Betpro account.');
    //   handleShow();
    //  }
    else {
      // setMsg('Deposit request created successfully.' )


      //  await checkdepositcheck(parsedUser.userID, 'deposit' , setMsg, handleShow, selectedPaymentOption);


      if (parsedUser.userActive == 1) {


        await checkPendingWithdraw(parsedUser.userID, 'withdraw', setMsg, handleShow, selectedPaymentOption);

      } else {
        setMsg('Please activate your account before \n requesting a withdrawal.');
        setDialogToDeposit(true);
        setDialogOnOff(false);
        handleShow();
      }

    }
  };





  // // Function to check for pending deposits
  // const checkdepositcheck = async (userName, tableName, setMsg, handleShow, selectedPaymentOption) => {
  //   try {


  //     // Create a cancel token and timeout
  //     const source = axios.CancelToken.source();
  //     const timeout = setTimeout(() => {
  //       source.cancel();
  //     }, 30000); // 20 seconds timeout

  //     // Prepare the parameters for the POST request
  //     const params = new URLSearchParams();
  //     params.append('user_id', userName);
  //     params.append('table_name', tableName);

  //     // Send the POST request to check for pending deposits
  //     const response = await axios.post(
  //       'https://api.betprowallet.pk/api_fetch_user_tr_status.php',
  //       params,
  //       {
  //         headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         cancelToken: source.token,
  //       }
  //     );

  //     // Clear the timeout
  //     clearTimeout(timeout);





  //   if(response.data.status === true){

  //     await checkPendingWithdraw(parsedUser.userID, 'withdraw', setMsg, handleShow, selectedPaymentOption);

  //   }else {

  //     setMsg('');


  //   }



  //   } catch (error) {
  //     if (axios.isCancel(error)) {
  //       setMsg('Request timed out. Please try again.');
  //     } else {
  //       setMsg('Please check your internet connection.');

  //     }

  //     setDialogOnOff(true);

  //     console.log(error);
  //     handleShow();

  //   }
  // };







  // Function to check for pending deposits
  const checkPendingWithdraw = async (userName, tableName, setMsg, handleShow, selectedPaymentOption) => {
    try {


      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 20 seconds timeout

      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('user_id', userName);
      params.append('table_name', tableName);

      // Send the POST request to check for pending deposits
      const response = await axios.post(
        'https://api.betprowallet.pk/api_fetch_pending_deposits.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);





      if (response.data[response.data.length - 1].status == '0') {


        setMsg('You have a withdraw in pending.');
        setDialogOnOff(true);

        handleShow();



      } else {



      

          if (amount && parsedUser.withdrawAmount && !isNaN(amount) && !isNaN(parsedUser.withdrawAmount) && (Number(amount) >= 1000 && Number(amount) <= Number(parsedUser.withdrawAmount))) {


         submitWithdraw(
          'withdraw',
          parsedUser.userID,
          parsedUser.name,
          ''+selectedPayment,
          amount,
          name || '',
          number || '',
          bankName || selectedPaymentOption,
          parsedUser.bp_username, // Image URL after upload
          '0',
          parsedUser.number,
          setMsg,
          handleShow
        );

      }else{
        setMsg('Withdraw Limit Exceed Please Contact on Helpline.');
        handleShow();
      }

      }



    } catch (error) {
      if (axios.isCancel(error)) {
        setMsg('Request timed out. Please try again.');
      } else {
        setMsg('Please check your internet connection.');

      }

      setDialogOnOff(true);

      console.log(error);
      handleShow();

    }
  };



  // Function to submit deposit details to the server
  const submitWithdraw = async (
    tableName,
    id,
    username,
    accType,
    amount,
    accTitle,
    accNum,
    bankName,
    betProUsername,
    status,
    whatsapp,
    setMsg,
    handleShow
  ) => {
    try {
      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('user_id', id);
      params.append('acc_type', accType);
      params.append('amount', amount);
      params.append('to', accTitle);
      params.append('acc', accNum);
      params.append('deposit_bank_name', bankName);
      params.append('bet_pro_username', betProUsername);
      params.append('proof', ''); // Image URL
      params.append('status', status);
      params.append('whatsapp', whatsapp);
      params.append('table_name', tableName);

      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 30 seconds timeout

      // Send the POST request to submit the deposit details
      const response = await axios.post(
        'https://api.betprowallet.pk/pendingUserInsert.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);

      // Handle the server's response
      if (response.data.status === 'success') {


        await sendFCMNotification('/topics/com.b.adminweb', 'Withdraw', 'Withdraw of Amount ' + amount);

        setMsg('Withdraw submitted successfully!');
        setIsPaymentSuccess(true);
        setTempAmount(amount);
        setShow(false);
        setLoading(false);







      } else {
        setMsg('Failed to submit withdraw. Please try again.');
        setDialogOnOff(true);
      handleShow();
      }

      
    } catch (error) {
      if (axios.isCancel(error)) {
        setMsg('Withdraw submission timed out. Please try again.');
      } else {
        setMsg('Failed to submit withdraw. Please check your internet connection.');
      }
      setDialogOnOff(true);
      handleShow();
    }
  };


  useEffect(()=>{
    if(parsedUser.userActive == 0){


      setMsg('Active your Account First');
      setDialogToDeposit(true);
      setDialogOnOff(false);
      handleShow();


    }
  })


  return (
    <div>

      <Helmet>
        {/* Page title optimized for the withdrawal page */}
        <title>Betpro Wallet Withdrawal - Fast & Secure Withdrawals</title>

        <link rel="icon" href="https://betprowallet.pk/favicon.ico" />

        {/* Canonical tag */}
        <link rel="canonical" href="https://betprowallet.pk/withdraw" />

        {/* Meta description focusing on withdrawing funds from Betpro Wallet */}
        <meta name="description" content="Withdraw your winnings quickly and securely from your Betpro Wallet account. ⚽️ Fast, reliable, and hassle-free withdrawal process with multiple payout options. Start withdrawing at Betprowallet.pk." />

        {/* Meta keywords for search engine optimization */}
        <meta name="keywords" content="Betpro Wallet withdrawal, fast withdrawals, secure withdrawals, withdraw winnings, Betpro Wallet payout options, online betting withdrawals, Betprowallet.pk withdrawal" />

        {/* Meta author */}
        <meta name="author" content="Betpro Wallet" />

        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Betpro Wallet Withdrawal - Fast & Secure Withdrawals" />
        <meta property="og:description" content="Withdraw your winnings quickly and securely from Betpro Wallet. Choose from multiple payout options for a fast, secure, and hassle-free withdrawal experience at Betprowallet.pk." />
        <meta property="og:url" content="https://betprowallet.pk/withdraw" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://betprowallet.pk/bpthumb1.jpg" />

        {/* Twitter Card for sharing on Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Betpro Wallet Withdrawal - Fast & Secure Withdrawals" />
        <meta name="twitter:description" content="Withdraw your winnings quickly and securely from Betpro Wallet. Fast, reliable, and multiple payout options available at Betprowallet.pk." />
        <meta name="twitter:image" content="https://betprowallet.pk/bpthumb1.jpg" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Betpro Wallet Withdrawal - Fast & Secure Withdrawals",
        "url": "https://betprowallet.pk/withdraw",
        "description": "Withdraw your winnings securely from your Betpro Wallet account. Enjoy fast payouts with multiple options for a hassle-free experience.",
        "publisher": {
          "@type": "Organization",
          "name": "Betpro Wallet"
        }
      }
    `}
        </script>
      </Helmet>


      <Header />

      <div className="main-deposit-wraper">
        <div className="container" style={{ padding: "0 1rem", maxWidth: "28rem" }}>
          <div className="middle-depsoit">
            <div className="fieldset-deposit">
              <div className="row">
                <div className="col-12">
                  <h2 className="mt-3 mb-4 text-center">Withdraw funds</h2>
                  <h3 className="hh3 mt-3">Payment Method</h3>
                  <hr />

                  <div className="row mt-3 mb-3">
                    {paymentOptions.map((option) => (
                      <div
                        key={option.id}
                        className="col-3 mt-2 paycardgird"
                        onClick={() => handleMethodChange(option.id)} // Make the whole card clickable
                        style={{ cursor: 'pointer' }} // Add cursor pointer for full card
                      >
                        <div className={`deposit-easypaisa  ${selectedPayment === option.id ? 'method-active' : ''}`}>
                          {/* Checkmark, visible only when the payment method is selected */}
                          {selectedPayment === option.id && (
                            <div className="checkmark">
                              <img src={tick} alt="Selected" hidden />
                            </div>
                          )}
                          <label className="label_for_cards" htmlFor={option.id}>
                            <input
                              type="radio"
                              id={option.id}
                              name="payment"
                              value={option.id}
                              checked={selectedPayment === option.id}
                              onChange={() => handleMethodChange(option.id)} // Keeps input checked
                              style={{ display: 'none' }} // Hide the radio button visually
                            />
                            <img src={option.image} alt={option.alt} />
                            <p className={`deposit-text`}>{option.text}</p>
                          </label>


                        </div>
                        <div>

                        </div>
                      </div>

                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>




          {selectedPayment && (
            <>

              <div className="deposit-amount">

                <p
                  className="form-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "10px",
                    fontSize: "10px",
                    color: "white"
                  }}
                >
                  <h1 className="title_h1" style={{ margin: "0px 0px 0px 5px" }}>{paymentOptions.find(option => option.id === selectedPayment).text}</h1>
                  <span style={{ textAlign: "end" }}>Amount Min Rs.1000 </span>
                </p>

                <div className="form-group d-flex align-center amount-box">
                  <div className="width-200">
                    <label htmlFor="enterAmount">Enter amount</label>
                  </div>
                  <div className=" relative">
                    <input
                      type="number"
                      id="enterAmount"
                      className="form-control"
                      placeholder="Enter Amount"
                      min={1000}
                      value={amount}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>


                <div className={`form-group flex items-center amount-box ${selectedPayment !== 3 ? "mb-4" : ""}`}>

                  <div className="width-200">
                    <label htmlFor="enterAccountNumber">
                    {placeholder[selectedPayment-1].title}: 
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      id="enterAccountNumber"
                      className="form-control"
                      value={number}
                      onChange={(e) => handleNumberChange(e)}
                      placeholder={placeholder[selectedPayment-1].title}
                    />

                  </div>
                </div>

                <div className="form-group flex items-center amount-box ">
                  <div className="width-200">
                    <label htmlFor="enterAccountName">{placeholder[selectedPayment-1].name}: </label>
                  </div>
                  <div className=" relative">
                    <input
                      type="text"
                      id="enterAccountName"
                      className="form-control"
                      value={name}
                      onChange={(e) => handleNameChange(e)}
                      placeholder={placeholder[selectedPayment-1].name}
                    />
                  </div>
                </div>


                {selectedPayment === 3 && (
                  <div className="form-group flex items-center amount-box mb-4">
                    <div className="width-200">
                      <label htmlFor="enterBankName">Enter bank name:</label>
                    </div>
                    <input
                      type="text"
                      id="enterBankName"
                      className="form-control"
                      value={bankName}
                      onChange={(e) => handleBankChange(e)}
                      placeholder="Enter bank name"
                    />
                  </div>
                )}
              </div>

              <div className="confirm-deposit w-full my-10">
                <button type="submit" onClick={handleSubmit}> {Number(withdrawTxt) ==  1 ?"something wrong...":"Confirm Withdraw"}</button>
              </div>
             

              {isPaymentSuccess && (
  <div className="withdraw-modal-container">
    <div className="withdraw-modal">
      <div className="payment-success">
        <img src={Icons.SuccessIcon} alt="Success Icon" />
        <h2>Withdraw Success!</h2>
        <p>Rs. {tempAmount}</p>
        <button onClick={handleSuccessBtnOk}>OK</button>
      </div>
    </div>
  </div>
)}


           

            </>
          )}


          <div className="row  m-2" >

          </div>

        






          <hr />
        </div>
      </div>

      <Loader show={loading} />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header >
          <Modal.Title>Withdraw Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Msg}</p>
          <hr />
          <p onClick={handleClose} className="model-ok">Ok</p>
        </Modal.Body>

      </Modal>
      {/* <Nav /> */}

      <Bottomtab />

    </div>

  );
};

export default Withdrawpage;
